<template>
   <div class="common-layout">
    <el-container>
        <el-aside width="210px" style="background:#fff;box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px">
            <div class="menu-title">
                <span>行业分类</span> 
            </div>
            <el-scrollbar :style="{'height':(windowHeight-135)+'px'}">
                <ul>
                    <!-- <li class="menu-item" :class="{'cur': selIndex==0}">
                        <a  @click.prevent="selIndustryFun('')">
                            <span>综述</span>
                        </a>
                    </li> -->
                    <li class="menu-item" v-for="(item,index) in industryList" :key="item.code" :class="{'cur': (index+1)==selIndex}">
                        <a @click.prevent="selIndustryFun(index+1)" :title="item.name">
                            <span>{{item.name}}</span>
                        </a>
                    </li>
                </ul>
            </el-scrollbar>
        </el-aside>
        <el-main  style="position: relative;padding:0px;">
            <el-scrollbar class='affix-container'   :style="{'height':(windowHeight-71)+'px'}"  style='padding:20px;padding-top:0px;' >
                <el-affix target=".affix-container" :offset="71" >
                    <div class="cd_page_nav" >
                        <span @click="goAnchor('gk')">行业概况</span>
                        <span @click="goAnchor('sc')">市场趋势</span>
                        <span @click="goAnchor('js')">技术发展</span>
                        <span @click="goAnchor('fx')">风险分析</span>
                    </div>
                </el-affix>
                <span id='gk' class="anchor_txt" style="margin-top:20px;">行业概况</span>
                <div style="margin:20px 0; ">
                    <el-row :gutter="20" style="padding:10px;background: #fff;margin-bottom:20px;">
                        <el-col :span="14" style="position: relative;">
                        <div style='position: absolute;z-index: 9;'>
                            <div class='col-d' :class="{'selcol':selEpType=='epNum'}" @click="selEpTypeFun ('epNum')">
                                <span class='name'>企业数量</span>
                                <p class='num'>{{epNum}}</p>
                                <span class='unit'>家</span>
                            </div>
                            <div class='col-d' :class="{'selcol':selEpType=='registeredcapital'}" @click="selEpTypeFun('registeredcapital')">
                                <span class='name'>注册资本</span>
                                <p class='num'>{{registeredcapital}}</p>
                                <span class='unit'>万元</span>
                            </div>
                            <div class='col-d' :class="{'selcol':selEpType=='insuredNum'}" @click="selEpTypeFun('insuredNum')">
                                <span class='name'>参保人数</span>
                                <p class='num'>{{insuredNum}}</p>
                                <span class='unit'>人</span>
                            </div> 
                        </div>
                        <div id='mapContainer' style="width:100%;height:500px;">
                        </div>
                        <!-- 地图地区Tooltip -->
                        <div ref='mapMarker' class="tipMarker top"></div>
                        </el-col>
                        <el-col :span="10">
                            <span class='span-txt'>本城市的优企列表</span>
                            <el-table v-loading="loadingShowOfEpList" element-loading-text="正在加载中..."
                                :data="epList" stripe style="width:100%;" :height='470' @sort-change='sortChangeFun' :default-sort="sortField" >
                                <el-table-column prop="regionName" label="地区" width="80" />
                                <el-table-column prop="name" label="企业名称"   >
                                    <template #default="scope">
                                        <el-link :underline="false" :href="`/#/ep/epAnalysis?id=`+scope.row.companyId" target="_blank"  class="ep-link">{{scope.row.name}}</el-link>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="startDate" sortable  label="成立日期" width="100"  />
                                <el-table-column prop="registeredcapital" sortable label="注册资本(万)" />
                                <el-table-column prop="insuredNum" sortable label="参保人数(人)" />
                            </el-table>
                            <el-pagination
                                class="page-cus"
                                @current-change="pageChangeFun"
                                :current-page="pageIndex"
                                :hide-on-single-page="epTotal<pageSize?true:false"
                                layout="prev, pager, next"
                                :total="epTotal">
                            </el-pagination>
                        </el-col>
                    </el-row>
                    <div style="background: #fff;padding:10px;margin-bottom:20px;">
                        <span class='span-txt'>同省各城市的行业概况</span>
                        <el-row :gutter="20">
                            <el-col :span="14">
                                <el-table :data="epAnalysisList" stripe style="width: 100%" :default-sort="{ prop: 'epNum', order: 'descending' }" 
                                :height='470' @header-click='headerChangeFun' :row-class-name="tableRowClassName" >
                                    <el-table-column type="index" width="60" label="排名" >
                                        <template #default="scope">
                                            <span class="ranking-top" :style='{"background": scope.$index>4?"":"#f56c6c"}'>{{ scope.$index + 1}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="regionName" label="地区" />
                                    <el-table-column prop="epNum" label="企业数量" sortable   />
                                    <el-table-column prop="registeredcapital" label="注册资本(万)" sortable />
                                    <el-table-column prop="insuredNum" label="参保人数(人)" sortable  />
                                </el-table>
                            </el-col>
                            <el-col :span="10">
                                <div :id="'chart_'+selIndex+'_12'" style="width:100%;height:470px;"> 
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div style="background: #fff;padding:10px;">
                                <span class='span-txt'>注册资本</span>
                                <br>
                                <span>在全省范围内，超大型约占同行业的<strong>{{ratioOfBigEp}}%</strong>，大型约占同行业的<strong>{{ratioOfOversizeEp}}%</strong>。</span>
                                <div :id="'chart_'+selIndex+'_4'" style="width:100%;height:350px;"></div>
                                
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div style="background: #fff;padding:10px;">
                                <span class='span-txt'>成立日期</span>
                                <br>
                                <span>在全省范围内，成熟型约占同行业的<strong>{{ratioOfExpandEp}}%</strong>，扩张型约占同行业的<strong>{{ratioOfMatureEp}}%</strong>。</span>
                                <div :id="'chart_'+selIndex+'_5'" style="width:100%;height:350px;"></div>
                                
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <span id='sc' class="anchor_txt">市场趋势</span>
                <div>
                    <div v-if='industryList && productList.filter(t=>t.industryCode==industryList[selIndex-1].code).length>0 ' style="background: #fff;margin:20px 0; padding:10px;">
                        <span class="span-txt">商品价格走势</span>
                        <div :id="'chart_'+selIndex+'_9'" style="width:100%;height:400px;"></div>
                    </div>
                    <el-row :gutter="20" v-if="industryList && industryList[selIndex-1].code=='K'" style="background: #fff;margin:20px 0; padding:10px;">
                        <el-col :span="12" >
                            <div style="background: #fff;padding:10px;">
                                <span class='span-txt'>近一年房价</span>
                                <div :id="'chart_'+selIndex+'_10'" style="width:100%;height:350px;"></div>
                            </div>
                        </el-col>
                        <el-col :span="12" >
                            <div style="background: #fff;padding:10px;">
                                <span class='span-txt'>近一年租金</span>
                                <div :id="'chart_'+selIndex+'_11'" style="width:100%;height:350px;"></div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin-top:20px;margin-bottom:20px">
                        <el-col :span="12" >
                            <div style="background: #fff;padding:10px;">
                                <span class='span-txt'>近5年在营企业变化</span>
                                <div :id="'chart_'+selIndex+'_1'" style="width:100%;height:350px;"></div>
                            </div>
                        </el-col>
                        <el-col :span="12" >
                            <div style="background: #fff;padding:10px;">
                                <span class='span-txt'>近5年新增企业变化</span>
                                <div :id="'chart_'+selIndex+'_2'" style="width:100%;height:350px;"></div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" style='margin: 20px -10px;'>
                        <el-col :span="12" >
                            <div style="background: #fff; padding:10px;">
                                <span class='span-txt'>{{new Date(curDate).getFullYear()}}年区县新增企业情况</span>
                                <el-table :data="addEpNumOfRegionList"  stripe :default-sort="{ prop: 'itemValue', order: 'descending' }" :height='470' style="width: 100%">
                                    <el-table-column type="index" width="60" label="排名" >
                                        <template #default="scope">
                                            <span class="ranking-top" :style='{"background": scope.$index>4?"":"#f56c6c"}'>{{ scope.$index + 1}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="regionName" label="地区" />
                                    <el-table-column prop="itemValue" sortable  label="新增企业(家)"  />
                                </el-table>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div style="background: #fff; padding:10px;" >
                                <span class='span-txt'>{{new Date(curDate).getFullYear()}}本省各城市新增企业情况</span>
                                <div :id="'chart_'+selIndex+'_3'" style="width:100%;height:470px;"></div>
                                <!-- <span class='span-txt'>{{selRegion}}前五的新增企业(成立日期)</span>
                                <el-table :data="addEpList" :height="260" stripe style="width:100%;">
                                    <el-table-column prop="regionName" label="地区"  width="80" />
                                    <el-table-column prop="name"   label="企业名"   />
                                    <el-table-column prop="startDate"   label="成立日期" width="100" />
                                    <el-table-column prop="registeredcapital"   label="注册资本(万元)" width="140" />
                                    <el-table-column prop="insuredNum"   label="参保人数(人)" width="140" />
                                </el-table> -->
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <span id='js' class="anchor_txt">技术发展</span>
                <el-row :gutter="20" style="background: #fff;padding:10px;margin: 20px 0px;">
                    <el-col :span="14" >
                        <el-table :data="levelsList" style="width:100%" stripe :height='470' :default-sort="{ prop: 'patentNum', order: 'descending' }" @header-click='headerChangeFun' :row-class-name="tableRowClassName">
                            <el-table-column type="index" width="60" label="排名" >
                                <template #default="scope">
                                    <span class="ranking-top" :style='{"background": scope.$index>4?"":"#f56c6c"}'>{{ scope.$index + 1}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="regionName" label="地区" width="180" />
                            <el-table-column prop="patentNum" label="专利" sortable  />
                            <!-- <el-table-column label="占全省" >
                                <template #default="scope">
                                    <div v-if='scope.row.patentNum' class="bar">
                                        <div class='bar_inner' :style="{width:(scope.row.patentNum?(scope.row.patentNum/numOfProvincePatent*100).toFixed(2):0)+'%'}">
                                        </div>
                                    </div>
                                    <span >{{scope.row.patentNum?(scope.row.patentNum/numOfProvincePatent*100).toFixed(2):''}}</span>
                                </template>
                            </el-table-column> -->
                            <el-table-column prop="worksrightNum" label="作品著作权" sortable  />
                            <!-- <el-table-column label="占全省" >
                                <template #default="scope">
                                    <div v-if='scope.row.worksrightNum' class="bar">
                                        <div class='bar_inner' :style="{width:(scope.row.worksrightNum?(scope.row.worksrightNum/numOfProviceWorkright*100).toFixed(2):0)+'%'}">
                                        </div>
                                    </div>
                                    <span >{{scope.row.worksrightNum?(scope.row.worksrightNum/numOfProviceWorkright*100).toFixed(2):''}}</span>
                                </template>
                            </el-table-column> -->
                            <el-table-column prop="copyrightNum" label="软件著作权" sortable  />
                            <!-- <el-table-column label="占全省" >
                                <template #default="scope">
                                    <div v-if='scope.row.copyrightNum' class="bar">
                                        <div class='bar_inner' :style="{width:(scope.row.copyrightNum?(scope.row.copyrightNum/numOfProviceCopyright*100).toFixed(2):0)+'%'}">
                                        </div>
                                    </div>
                                    <span >{{scope.row.copyrightNum?(scope.row.copyrightNum/numOfProviceCopyright*100).toFixed(2):''}}</span>
                                </template>
                            </el-table-column> -->
                        </el-table>
                    </el-col>
                    <el-col :span="10" >
                        <div :id="'chart_'+selIndex+'_13'" style="width:100%;height:470px;"></div>
                    </el-col>
                </el-row>
                <span id='fx' class="anchor_txt"> 风险分析</span>
                    <el-row :gutter="20" style="background: #fff;padding:10px;margin: 20px 0px;">
                        <el-col :span="12" >
                            <span class='span-txt'>成立日期各阶段的企业注销情况</span>
                            <el-table ref='cancelEpListRef' :data="cancelEpList" style="width:100%" stripe @row-click='rowClickFun' highlight-current-row
                                @current-change="handleCurrentChange">
                                <el-table-column prop="name" label="阶段" width="180" />
                                <el-table-column label="存活率" >
                                    <template #default="scope">
                                        <div v-if='scope.row.survivalRate' class="bar">
                                            <div class='bar_inner' :style="{width:(scope.row.survivalRate?scope.row.survivalRate:0)+'%'}">
                                            </div>
                                        </div>
                                        <span >{{scope.row.survivalRate?scope.row.survivalRate:'-'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="cancelNum" label="注销企业"  />
                                <el-table-column prop="epNum" label="总企业数"  />
                                
                            </el-table>
                        </el-col>
                        <el-col :span="12" >
                            <div :id="'chart_'+selIndex+'_6'" style="width:100%;height:350px;"></div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" >
                        <el-col :span="12" >
                            <div style="background: #fff;padding:10px;">
                                <span class='span-txt'>司法风险趋势</span>
                                <div :id="'chart_'+selIndex+'_7'" style="width:100%;height:350px;"></div>
                            </div>
                        </el-col>
                        <el-col :span="12" >
                            <div style="background: #fff;padding:10px;">
                                <span class='span-txt'>经营风险趋势</span>
                                <div :id="'chart_'+selIndex+'_8'" style="width:100%;height:350px;"></div>
                            </div>
                        </el-col>
                    </el-row>
            </el-scrollbar>
            <el-backtop :right="100" :bottom="100" />
        </el-main>
    </el-container>
  </div>
</template>
<script>
import store from '../../store'
import { ref } from '@vue/reactivity'
import { computed, onMounted,nextTick, onBeforeUnmount, watch } from '@vue/runtime-core';
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import {getRegionIndustryList,getNextRegionItemData,getRegionItemDataList,getRegionEpCount,getRegionEpList,getRegionEpLnglat} from '@/http/epApi'
import {getProductDataList,getHouseDataList,getProductList} from '@/http/ecoApi'
import{getCodeRegionList,addUserLog} from '@/http/basicsApi'
import * as echarts from 'echarts'
import {getDateList,parseTime,colors} from '@/utils/index'
import {scrollTo} from '@/utils/scroll-to'
import axios from 'axios'
// 引入封装的nprogress.js
import { start, close } from '@/utils/nprogress';
import { useRouter } from 'vue-router';
export default {
    setup() {
        const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
        let echartsAry=ref([]);//echart对象
        let router=useRouter();
        //选中地区信息
        let regionCode=computed(()=> store.state.curRegionCode);
        //行业门类列表

        let industryList=ref();//行业分类
        let selIndex=ref(1);//选中行业的索引
        const getIndustryListFun= async()=>{
            let result=await getRegionIndustryList(regionCode.value);
            //console.log(result);
            if(result.code==20000){
                industryList.value=result.data;
                //地图
                nextTick(()=>{
                    initAMap();
                    //getNextRegionItemDataFun();
                })
                //当前地区的指标数据
                await getRegionItemDataListFun();
                
                //await getProvinceItemDataFun();
                //获取企业列表
                pageIndex.value=1;
                await getRegionEpListFun(1);
                //获取本省的城市列表
                await getCityListFun();
                //获取各个行业的商品价格
                getProductPriceFun();
                if(industryList.value[selIndex.value-1].code=="K"){//房地产
                    getHouseDataListFun();
                }
                //近5年在营企业数量、新增企业数量、行政处罚的企业数量、失信被执行人的企业数量
                getItemDataListFun();
                //最新一年下级地区新增企业数量
                getNextRegionAddEpNumFun();
                //记录用户操作日志
                addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载',`{"regionCode":"${regionCode.value}","industryCode":"${industryList.value[selIndex.value-1].code}"}`);
            }
            
        }
        const selIndustryFun=async (index)=>{
            start();
            document.querySelector('.affix-container .el-scrollbar__wrap').scrollTop=0
            selIndex.value=index;
            echartsAry.value=[];
            epTotal.value=0;//本城市的企业总数量
            //地图
            await getNextRegionItemDataFun();
           
            //当前地区的指标数据
            await getRegionItemDataListFun();
           
            //await getProvinceItemDataFun();
           
            //获取企业列表
            pageIndex.value=1;
            await getRegionEpListFun(1);
            //获取本省的城市列表
            await getCityListFun();
            //获取各个行业的商品价格
            getProductPriceFun();
            if(industryList.value[selIndex.value-1].code=="K"){//房地产
                getHouseDataListFun();
            }
            //近5年在营企业数量、新增企业数量
            getItemDataListFun();
            //最新一年下级地区新增企业数量
            getNextRegionAddEpNumFun();
            
            close();
            //记录用户操作日志
            addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'选行业',`{"regionCode":"${regionCode.value}","industryCode":"${industryList.value[selIndex.value-1].code}"}`);
        }
        let selEpType=ref('epNum')
        const selEpTypeFun=(type)=>{
            selEpType.value=type;
            //地图显示企业数量
            getNextRegionItemDataFun();
        }
        //获取本省的城市列表
        let cityList=[];
        const getCityListFun=async()=>{
            if(cityList.length==0){
                let params={
                    parent:regionCode.value.substr(0,2)+'0000'
                }
                let res=await getCodeRegionList(params);
                if(res.code==20000){
                  cityList=res.data;
                }
            }
            //最新一年同级地区新增企业数量
            await getReionAddEpNumFun();
            //获取各个城市的专利、作品著作权的数量
            await getRegionWorksFun();
        }
        //初始化地图,加载高德地图
        let map;
        let districtExplorer=null;
        const mapMarker=ref(null);
        var pointSimplifierIns;
        //当前聚焦的区域
        var currentAreaNode = null;
        let colorLevel=ref([]);//色阶数组
        var mapColors = ['rgb(207,1,73)','rgb(233,90,22)','rgb(255,211,32)','rgb(171,203,32)','rgb(0,151,99)','rgb(3,151,239)'];
        const initAMap=()=>{
            //创建地图
            map = new AMap.Map('mapContainer', {
                zoom: 4
            });
            //just some colors
                AMapUI.load(['ui/geo/DistrictExplorer', 'lib/$', 'ui/misc/PointSimplifier'], function(DistrictExplorer, $, PointSimplifier) {// eslint-disable-line no-unused-vars
                //创建一个实例
                districtExplorer = window.districtExplorer = new DistrictExplorer({
                    map: map,
                    eventSupport: true, //打开事件支持
                    preload: [regionCode.value] //预加载全国
                });
                //鼠标hover提示内容
                var tipMarker = new AMap.Marker({
                    content: mapMarker.value,
                    offset: new AMap.Pixel(0, 0),
                    bubble: true
                });
                //根据Hover状态设置相关样式
                function toggleHoverFeature(feature, isHover, position) {
                    tipMarker.setMap(isHover ? map : null);
                    if (!feature) {
                        return;
                    }
                    var props = feature.properties;
                    if (isHover) {
                        let obj= mapRegionDataList.value.find(t=>t.regionCode==props.adcode);
                        //更新提示内容
                        if(selEpType.value=='epNum'){
                            mapMarker.value.innerHTML=props.name+'<br/>企业数量:'+(obj?obj.itemValue:'-')+'家' ;
                        }
                        else if(selEpType.value=='registeredcapital'){
                            mapMarker.value.innerHTML=props.name+'<br/>注册资本:'+(obj?obj.itemValue:'-')+'万元' ;
                        }
                        else if(selEpType.value=='insuredNum'){
                            mapMarker.value.innerHTML=props.name+'<br/>参保人数:'+(obj?obj.itemValue:'-')+'人' ;
                        }
                        
                        //更新位置
                        tipMarker.setPosition(position || props.center);
                    }
                    //更新相关多边形的样式
                    var polys = districtExplorer.findFeaturePolygonsByAdcode(props.adcode);
                    for (var i = 0, len = polys.length; i < len; i++) {

                        polys[i].setOptions({
                            fillOpacity: isHover ? 1 : 0.7
                        });
                    }
                }

                //监听feature的hover事件
                districtExplorer.on('featureMouseout featureMouseover', function(e, feature) {
                    toggleHoverFeature(feature, e.type === 'featureMouseover',
                        e.originalEvent ? e.originalEvent.lnglat : null);
                });

                //监听鼠标在feature上滑动
                districtExplorer.on('featureMousemove', function(e, feature) {// eslint-disable-line no-unused-vars
                    //更新提示位置
                    tipMarker.setPosition(e.originalEvent.lnglat);
                });

                //feature被点击
                districtExplorer.on('featureClick', function(e, feature) {
                    var props = feature.properties;
                    //如果存在子节点
                    //if (props.childrenNum > 0) {
                        //切换聚焦区域
                        switch2AreaNode(props.adcode,props.childrenNum>0?true:false);
                    //}
                });
                //这里创建一个海量点组件
                pointSimplifierIns = new PointSimplifier({
                    zIndex: 115,
                    autoSetFitView: false,
                    map: map, //所属的地图实例

                    getPosition: function(item) {
                        return item.pos;
                    },
                    getHoverTitle: function(item, idx) {// eslint-disable-line no-unused-vars
                        //console.log(item);
                        //console.log(idx);
                        //if (item.gid === -1) {
                            return item.name;
                        //}
                    },
                    //使用GroupStyleRender
                    renderConstructor: PointSimplifier.Render.Canvas.GroupStyleRender,
                    renderOptions: {
                        //点的样式
                        pointStyle: {
                            fillStyle: 'red',
                            width: 5,
                            height: 5
                        },
                        getGroupId: function(item) {
                            return item.gid;
                        },
                        groupStyleOptions: function(gid) {// eslint-disable-line no-unused-vars

                            // //未知区域
                            // if (gid === -1) {

                            //     return {
                            //         pointStyle: {
                            //             fillStyle: null,
                            //             strokeStyle: null,
                            //             lineWidth: 2,
                            //             width: 16,
                            //             height: 16
                            //         }
                            //     };
                            // }

                            return {
                                pointStyle: {
                                    fillStyle:'#002FA7',// colors[gid % colors.length],
                                    //strokeStyle: 'rgba(255,255,255,0.3)',
                                    lineWidth: 1
                                }
                            };
                        }

                    }
                });
                //switch2AreaNode(regionCode.value);
                getNextRegionItemDataFun();
            });
        }
        //绘制某个区域的边界
        function renderAreaPolygons(areaNode) {
            //更新地图视野
            map.setBounds(areaNode.getBounds(), null, null, true);

            //清除已有的绘制内容
            districtExplorer.clearFeaturePolygons();
            //绘制子区域
            districtExplorer.renderSubFeatures(areaNode, function(feature, i) {// eslint-disable-line no-unused-vars
                let {fillColor}= getFillColorFun(feature.properties.adcode);
                return {
                    cursor: 'default',
                    bubble: true,
                    strokeColor: '#002FA7', //线颜色
                    strokeOpacity: 1, //线透明度
                    strokeWeight: 1, //线宽
                    fillColor: fillColor, //填充色
                    fillOpacity: 0.7, //填充透明度
                };
            });

            //绘制父区域
            districtExplorer.renderParentFeature(areaNode, {
                cursor: 'default',
                bubble: true,
                strokeColor: '#002FA7', //线颜色
                strokeOpacity: 1, //线透明度
                strokeWeight: 1, //线宽
                fillColor: null, //填充色
                fillOpacity: 0, //填充透明度
            });
        }
        //切换区域后刷新显示内容
        function refreshAreaNode(areaNode) {
            districtExplorer.setHoverFeature(null);
            renderAreaPolygons(areaNode);
        }
        //切换区域
        function switch2AreaNode(adcode,isExistChild, callback) {

            // if (currentAreaNode && ('' + currentAreaNode.getAdcode() === '' + adcode)) {
            //     return;
            // }

            loadAreaNode(adcode,isExistChild, function(error, areaNode) {
                if (error) {
                    if (callback) {
                        callback(error);
                    }
                    return;
                }
                currentAreaNode = window.currentAreaNode = areaNode;
                //设置当前使用的定位用节点
                districtExplorer.setAreaNodesForLocating([currentAreaNode]);
                refreshAreaNode(areaNode);

                if (callback) {
                    callback(null, areaNode);
                }
            });
        }
        function renderGroupedPoints(groups) {
            var pointsData = [];
            for (var i = 0, len = groups.length; i < len; i++) {
                var gid = groups[i].subFeatureIndex;
                for (var j = 0, jlen = groups[i].points.length; j < jlen; j++) {
                    pointsData.push({
                        gid: gid,
                        name:groups[i].points[j].name,
                        pos: groups[i].points[j].pos
                    });
                }
            }
            //console.log(pointsData);
            pointSimplifierIns.setData(pointsData);
        }
        //加载区域
        function loadAreaNode(adcode,isExistChild, callback){
            districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
                if (error) {
                    if (callback) {
                        callback(error);
                    }
                    //console.error(error);
                    return;
                }
                if(!isExistChild){
                    //var points =await getGroupedPoints(adcode);
                    //console.log(adcode);
                    let params={
                        pageSize:1,
                        district:adcode.toString(),
                        province:adcode.toString().substr(0,2)+'0000',
                        industryCodea:industryList.value[selIndex.value-1].code,
                        state:1
                    };
                    getRegionEpLnglat(params).then(res=>{
                        //console.log(res);
                        if(res.code==20000){
                            console.log(res);
                            let points=res.data;
                            //当前子区域聚合
                            var groups = areaNode.groupByPosition(points, function(item) {
                                return item;
                            });
                            console.log(groups);
                            renderGroupedPoints(groups);
                        }
                    });
                    
                }
                else{
                    pointSimplifierIns.setData([]);//清空企业位置坐标
                }
                if (callback) {
                    callback(null, areaNode);
                }
            });
        }
        //获取地图地区的透明度
        const getFillColorFun=(adcode)=>{
            let fillColor=null;
        
            //把数值分为六段色阶（4%，11%，23%，42%，70%）
            var filterArray = mapRegionDataList.value.filter((e)=> { return e.regionCode ==adcode; });
            if(filterArray && filterArray.length>0 && filterArray[0]['itemValue'] && colorLevel.value.length>0){
            let val=filterArray[0]['itemValue'];
            if(val>=colorLevel.value[0]){
                fillColor=mapColors[0];
            }
            else if(val>=colorLevel.value[1]){
                fillColor=mapColors[1];
            }
            else if(val>=colorLevel.value[2]){
                fillColor=mapColors[2];
            }
            else if(val>=colorLevel.value[3]){
                fillColor=mapColors[3];
            }
            else if(val>=colorLevel.value[4]){
                fillColor=mapColors[4]
            }
            else{
                fillColor=mapColors[5]
            }
            }  
            return{fillColor}
        }
        //获取下级地区的指标数据
        let mapRegionDataList=ref();
        const getNextRegionItemDataFun=async()=>{
            let itemId='';
            if(selEpType.value=='epNum'){//企业数量
                itemId='60000500';
            }
            else if(selEpType.value=='registeredcapital'){//注册资本
                itemId='60000501';
            }
            else if(selEpType.value=='insuredNum'){//参保人数
                itemId='60000502';
            }
            let param={regionCode:regionCode.value,
            objId:industryList.value[selIndex.value-1].code,
            itemId:itemId
            };
            colorLevel.value=[];
            //console.log(param);
            let res=await getNextRegionItemData(param);
            //console.log(res);
            if(res.code==20000 && industryList.value[selIndex.value-1].code==param.objId){
                colorLevel.value=[];
                nextTick(()=>{
                    mapRegionDataList.value=res.data;
                    //色阶
                    //如果没有传入值，Math.max()(返回传入值中的最大值)将返回 -Infinity，Math.min()(返回传入值中的最小值)将返回Infinity。
                    let maxVal=Math.max.apply(Math, res.data.map(item=>item.itemValue).filter(item=>item))//指标项值最大值
                    let minVal=Math.min.apply(Math, res.data.map(item=>item.itemValue).filter(item=>item))//指标项值最小值
                    //把数值分为六段色阶
                    if(!Object.is(-Infinity, maxVal) || !Object.is(Infinity, minVal)){
                        let val=maxVal-minVal;
                        if(val==0){//只有一个值或者都是一个数值
                            colorLevel.value.push(val);
                        }
                        else{
                            colorLevel.value.push(Math.floor(val*0.75+minVal));//Math.floor(x)向下舍入
                            colorLevel.value.push(Math.floor(val*0.50+minVal));
                            colorLevel.value.push(Math.floor(val*0.30+minVal));
                            colorLevel.value.push(Math.floor(val*0.15+minVal));
                            colorLevel.value.push(Math.floor(val*0.05+minVal));
                        }
                    }

                    switch2AreaNode(regionCode.value,true);
                })
            }
        }
        
        //获取当前地区的指标数据
       
        let numOfBigEp=ref();//注册资本大型的企业数量
        let numOfOversizeEp=ref();//注册资本超大型的企业数量
        let numOfExpandEp=ref();//成立日期扩张型的企业数量
        let numOfMatureEp=ref();//成立日期成熟型的企业数量
        let cancelEpList=ref([]);//注销企业列表
        let cancelEpListRef=ref();//table对象
        let currentRow=ref();//table选中的行
        const getRegionItemDataListFun=async()=>{
            numOfBigEp.value=null;
            numOfOversizeEp.value=null;
            numOfExpandEp.value=null;
            numOfMatureEp.value=null;
            cancelEpList.value=[];
            let params={
                regionCode:regionCode.value,
                objId:industryList.value[selIndex.value-1].code,
                itemId:'60000505,60000506,60000507,60000508,60000509,60000510,60000511,60000512,60000513,60000514,60000517,60000518,60000519,60000520,60000521,60000522,60000523,60000524',
                pageSize:1,
            };
            //console.log(params)
            let res=await getRegionItemDataList(params);
            //console.log(res);
            if(res.code==20000 && industryList.value[selIndex.value-1].code==params.objId){
                numOfBigEp.value=null;
                numOfOversizeEp.value=null;
                numOfExpandEp.value=null;
                numOfMatureEp.value=null;
                cancelEpList.value=[];
                //注册资本——漏斗图
                let registeredcapitalAry=[];
                let obj=res.data.dataList.find(t=>t.itemId=='60000505');
                registeredcapitalAry.push({
                    value:obj? obj.itemValue:'-', name: '50万以内(微型)'
                })
                
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000506');
                registeredcapitalAry.push({
                    value: obj?obj.itemValue:'-', name: '50-100万(小型)'
                })
                
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000507');
                registeredcapitalAry.push({
                    value: obj?obj.itemValue:'-', name: '100-500万(中型)'
                })
                
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000508');
                
                registeredcapitalAry.push({
                    value:obj? obj.itemValue:'-', name: '500-1000万(中高型)'
                })
                
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000509');
                registeredcapitalAry.push({
                    value:obj? obj.itemValue:'-', name: '1000-5000万(大型)'
                })
                if(obj){
                    numOfBigEp.value=obj.itemValue;
                }
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000510');
                registeredcapitalAry.push({
                    value:obj? obj.itemValue:'-', name: '5000万以上(超大型)'
                })
                if(obj){
                    numOfOversizeEp.value=obj.itemValue;
                }
                //成立日期——漏斗图
                let startDateAry=[];

                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000511');
                startDateAry.push({
                    value:obj? obj.itemValue:'-', name: '2年以内(初创型)'
                })
                let cancelObj=res.data.dataList.find(t=>t.itemId=='60000518');
                let epNumObj=res.data.dataList.find(t=>t.itemId=='60000517');
                //初创型
                cancelEpList.value.push({
                    name:"2年内(初创型)",
                    cancelNumItemId:'60000518',
                    epNumItemId:'60000517',
                    survivalRate:obj && epNumObj && epNumObj.itemValue!=0?(obj.itemValue/epNumObj.itemValue*100).toFixed(2) :null,
                    cancelNum:cancelObj?cancelObj.itemValue:'-',
                    epNum:epNumObj?epNumObj.itemValue:'-'
                });
                obj=null;
                cancelObj=null;
                epNumObj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000512');
                startDateAry.push({
                    value:obj? obj.itemValue:'-', name: '2-5年(成长型)'
                })
                cancelObj=res.data.dataList.find(t=>t.itemId=='60000520');
                epNumObj=res.data.dataList.find(t=>t.itemId=='60000519');
                //成长型
                cancelEpList.value.push({
                    name:"2-5年(成长型)",
                    cancelNumItemId:'60000520',
                    epNumItemId:'60000519',
                    survivalRate:obj && epNumObj && epNumObj.itemValue!=0?(obj.itemValue/epNumObj.itemValue*100).toFixed(2) :null,
                    cancelNum:cancelObj?cancelObj.itemValue:'-',
                    epNum:epNumObj?epNumObj.itemValue:'-'
                }); 
                obj=null;
                cancelObj=null;
                epNumObj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000513');
                startDateAry.push({
                    value:obj? obj.itemValue:'-', name: '5-10年(扩张型)'
                })
                if(obj){
                    numOfExpandEp.value=obj.itemValue;
                }
                cancelObj=res.data.dataList.find(t=>t.itemId=='60000522');
                epNumObj=res.data.dataList.find(t=>t.itemId=='60000521');
                //成熟型
                cancelEpList.value.push({
                    name:"5-10年(成熟型)",
                    cancelNumItemId:'60000522',
                    epNumItemId:'60000521',
                    survivalRate:obj && epNumObj && epNumObj.itemValue!=0?(obj.itemValue/epNumObj.itemValue*100).toFixed(2) :null,
                    cancelNum:cancelObj?cancelObj.itemValue:'-',
                    epNum:epNumObj?epNumObj.itemValue:'-'
                }); 
                obj=null;
                cancelObj=null;
                epNumObj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000514');
                startDateAry.push({
                    value:obj? obj.itemValue:'-', name: '10年以上(成熟型)'
                })
                if(obj){
                    numOfMatureEp.value=obj.itemValue;
                }
                cancelObj=res.data.dataList.find(t=>t.itemId=='60000524');
                epNumObj=res.data.dataList.find(t=>t.itemId=='60000523');
                //成熟型
                cancelEpList.value.push({
                    name:"10年以上(成熟型)",
                    cancelNumItemId:'60000524',
                    epNumItemId:'60000523',
                    survivalRate:obj && epNumObj && epNumObj.itemValue!=0?(obj.itemValue/epNumObj.itemValue*100).toFixed(2) :null,
                    cancelNum:cancelObj?cancelObj.itemValue:'-',
                    epNum:epNumObj?epNumObj.itemValue:'-'
                }); 
                
                getRegionEpCancelFun('2年内(初创型)','60000518','60000517');
                nextTick(()=>{
                    cancelEpListRef.value.setCurrentRow(cancelEpList.value[0]);
                   
                    var chartDom4 = document.getElementById('chart_'+selIndex.value+'_4');
                    var myChart4 = echarts.getInstanceByDom(chartDom4);
                    if(myChart4){
                        myChart4.clear();
                    }
                    else{
                        myChart4 = echarts.init(chartDom4);
                    }
                    let option = {
                        color:colors,
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter: '{a} <br/>{b} : {c}家'
                        },
                        // legend: {},
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            top:30,
                            containLabel: true
                        },
                        xAxis: {
                            type: 'value',
                            boundaryGap: [0, 0.01]
                        },
                        yAxis: {
                            type: 'category',
                            data: registeredcapitalAry.map(t=>t.name),
                        },
                        series: [
                            {
                            name: '注册资本',
                            type: 'bar',
                            barWidth:'40%',
                            tooltip: {
                                valueFormatter: function (value) {
                                return (value?value:'-') + '家';
                                }
                            },
                            data:registeredcapitalAry
                        }]
                    };
                    option && myChart4.setOption(option);
                    echartsAry.value.push(myChart4);

                    var chartDom5 = document.getElementById('chart_'+selIndex.value+'_5');
                    var myChart5 = echarts.getInstanceByDom(chartDom5);
                    if(myChart5){
                        myChart5.clear();
                    }
                    else{
                        myChart5 = echarts.init(chartDom5);
                    }
                    option.yAxis.data=startDateAry.map(t=>t.name);
                    option.series[0].name='成立日期';
                    option.series[0].itemStyle={color:colors[1]};
                    option.series[0].data=startDateAry;
                    option && myChart5.setOption(option);
                    echartsAry.value.push(myChart5);
                })
            }
        }
        //获取省级地区的指标数据
        let numOfProvinceBigEp=ref();
        let numOfProvinceOversizeEp=ref();
        let numOfProvinceExpandEp=ref();
        let numOfProvinceMatureEp=ref();
        let ratioOfBigEp=computed(()=>{
            if(numOfBigEp.value && numOfProvinceBigEp.value && numOfProvinceBigEp.value!=0){
                return  (numOfBigEp.value/numOfProvinceBigEp.value*100).toFixed(2)
            }
            else{
                return  '-'
            }
        });
        let ratioOfOversizeEp=computed(()=>{
            if(numOfOversizeEp.value && numOfProvinceOversizeEp.value && numOfProvinceOversizeEp.value!=0){
                return  (numOfOversizeEp.value/numOfProvinceOversizeEp.value*100).toFixed(2)
            }
            else{
                return  '-'
            }
        });
        let ratioOfExpandEp=computed(()=>{
            if(numOfExpandEp.value && numOfProvinceExpandEp.value && numOfProvinceExpandEp.value!=0){
                return  (numOfExpandEp.value/numOfProvinceExpandEp.value*100).toFixed(2)
            }
            else{
                return  '-'
            }
        });
        let ratioOfMatureEp=computed(()=>{
            if(numOfMatureEp.value && numOfProvinceMatureEp.value && numOfProvinceMatureEp.value!=0){
                return  (numOfMatureEp.value/numOfProvinceMatureEp.value*100).toFixed(2)
            }
            else{
                return  '-'
            }
        });
        /*
        let numOfProvincePatent=ref();//省级地区的专利数量
        let numOfProviceWorkright=ref();//省级地区的作品著作权
        let numOfProviceCopyright=ref();//省级地区的软件著作权
        const getProvinceItemDataFun=async()=>{
            numOfProvinceBigEp.value=null;
            numOfProvinceOversizeEp.value=null;
            numOfProvinceExpandEp.value=null;
            numOfProvinceMatureEp.value=null;
            numOfProvincePatent.value=null;
            numOfProviceWorkright.value=null;
            numOfProviceCopyright.value=null;
            let params={
                regionCode:regionCode.value.substr(0,2)+'0000',
                objId:industryList.value[selIndex.value-1].code,
                itemId:'60000509,60000510,60000513,60000514,60000515,60000516,60000525',
                pageSize:1,
            };
            //console.log(params)
            let res=await getRegionItemDataList(params);
            //console.log(res);
            if(res.code==20000 && industryList.value[selIndex.value-1].code==params.objId){
                numOfProvinceBigEp.value=null;
                numOfProvinceOversizeEp.value=null;
                numOfProvinceExpandEp.value=null;
                numOfProvinceMatureEp.value=null;
                numOfProvincePatent.value=null;
                numOfProviceWorkright.value=null;
                numOfProviceCopyright.value=null;
                let obj=res.data.dataList.find(t=>t.itemId=='60000509');
                if(obj){
                    numOfProvinceBigEp.value=obj.itemValue;
                }
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000510');
                if(obj){
                    numOfProvinceOversizeEp.value=obj.itemValue;
                }
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000513');
                if(obj){
                    numOfProvinceExpandEp.value=obj.itemValue;
                }
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000514');
                if(obj){
                    numOfProvinceMatureEp.value=obj.itemValue;
                }
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000515');
                if(obj){
                    numOfProvincePatent.value=obj.itemValue;
                }
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000516');
                if(obj){
                    numOfProviceWorkright.value=obj.itemValue;
                }
                obj=null;
                obj=res.data.dataList.find(t=>t.itemId=='60000525');
                if(obj){
                    numOfProviceCopyright.value=obj.itemValue;
                }
            }
        }
        */
        //获取企业列表
        let epTotal=ref(0);
        let pageIndex=ref(1);
        let pageSize=ref(10);
        let epList=ref();
        let sortField=ref({ prop: 'startDate', order: 'descending' });
        let loadingShowOfEpList=ref(true);//企业列表显示"正在加载"
        const getRegionEpListFun= async(type)=>{
            // //排序字段
            // let orderFields='';
            // if(selEpType.value=='epNum'){//成立日期排序
            //     orderFields='start_date'
            // }
            // else if(selEpType.value=='registeredcapital')//注册资本排序
            // {
            //     orderFields='registeredcapital desc'
            // }
            // else if(selEpType.value=="insuredNum")//参保人数
            // {
            //     orderFields='insured_number desc'
            // }
            loadingShowOfEpList.value=true;
            let params={
                province:regionCode.value.substr(0,2)+'0000',
                city:regionCode.value,
                industryCodeA:industryList.value[selIndex.value-1].code,
                state:1,
                pageIndex:pageIndex.value,
                pageSize:pageSize.value,
                orderFields:sortField.value.prop?sortField.value.prop+ (sortField.value.order ==='descending'?' desc':''):'' // 'registeredcapital desc'
            }
            console.log(params);
            //获取企业数量
            if(type==1){
                let res_count= await getRegionEpCount(params);
                if(res_count.code==20000){
                    epTotal.value=res_count.data.totalCount;
                }
            }
            let res=await getRegionEpList(params);
            console.log(res);
            if(res.code==20000 && industryList.value[selIndex.value-1].code==params.industryCodeA){
                //epTotal.value=res.data.totalCount;
                epList.value=res.data;
            }
            loadingShowOfEpList.value=false;
        }
        //单击分页
        const pageChangeFun=(current)=>{
            pageIndex.value = current;
            //企业查询
            getRegionEpListFun(2);
        }
        //排序
        const sortChangeFun=(filed)=>{
            //console.log(filed);
            sortField.value.prop=filed.prop;
            sortField.value.order=filed.order;
            pageIndex.value = 1;
            getRegionEpListFun(2);
        }
        //获取各个行业的商品价格
        let productList=ref([{industryCode:'A',product:'w000000038,w000000039,w000000041,w000000046,w000000047'},
            {industryCode:'B',product:'w000000024,w000000032'},{industryCode:'C',product:'w000000001,w000000002,w000000035,w000000036,w000000024,w000000025'},
            {industryCode:'D',product:'w000000021,w000000028'},{industryCode:'E',product:'w000000001,w000000035,w000000036,w000000006'},
            {industryCode:'K',product:'w000000001,w000000035,w000000036,w000000006'},{industryCode:'G',product:'w000000024,w000000025'}]);
            // {industryCode:'H',product:'w000000240,w000000193'}
        let myChart9=null;
        const getProductPriceFun=async()=>{
            let industryCode=industryList.value[selIndex.value-1].code;
            let pro= productList.value.find(t=>t.industryCode==industryCode);
            if(!pro){
                if(myChart9){
                    echarts.dispose(myChart9)
                }
                return;
            }
            let dateAry=getDateList('4');//旬
            let params={
                regionCode:'0',
                proId:pro.product,
                itemId:'50000013',
                dateType:1,
                date:dateAry[0],
                endDate:dateAry[dateAry.length-1],
                pageSize:1,
            };
            //console.log(params);
            let series=[];
            axios.all([getProductDataList(params), getProductList({ids:pro.product,pageIndex:1,pageSize:1})])
            .then(axios.spread(function (res, resp2) {	// spread是将各请求结果拆分返回，如果不使用改方法，则最后的相应结果为一个数组[resp1, resp2]
                // 两个请求现在都执行完成
                //console.log(resp2);
                let legendData=[];
                pro.product.split(',').forEach(t=>{
                    let dataAry=[];
                    dateAry.forEach(d=>{
                       let obj=res.data.dataList.find(el=>el.proId==t && new Date(el.date).getTime()==new Date(d).getTime());
                       dataAry.push(obj?obj['p50000013']:'-')
                    })
                    let productInfo=resp2.data.dataList.find(p=>p.id==t);
                    legendData.push(productInfo.name)
                    series.push({
                        name: productInfo.name,
                        type: 'line',
                        tooltip: {
                            valueFormatter: function (value) {
                            return (value?value:'-') + '元/'+productInfo.unit;
                            }
                        },
                        data: dataAry
                    });
                    
               })
                nextTick(()=>{
                    var chartDom9 = document.getElementById('chart_'+selIndex.value+'_9');
                    var myChart9 = echarts.getInstanceByDom(chartDom9);
                    if(myChart9){
                        myChart9.clear();
                    }
                    else{
                        myChart9 = echarts.init(chartDom9);
                    }
                    let option = {
                        color:colors,
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: legendData
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: dateAry
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: series
                        };
                    option && myChart9.setOption(option);
                    echartsAry.value.push(myChart9);
                })
            }))
        }
        //获取房地产的房价和租金
        const getHouseDataListFun=async()=>{
            let dateAry=getDateList('3');//月
            let params={
                regionCode:regionCode.value,
                itemId:'50000001,50000002,50000003,50000005',
                dateType:1,
                date:dateAry[0],
                endDate:dateAry[dateAry.length-1],
                pageIndex:1,
                pageSize:1
            };
            //console.log(params);
            let res=await getHouseDataList(params);
            //console.log(res);
            if(res.code==20000){
                let zhuzhaiPriceData=[];
                let shangpuPriceData=[];
                let zhuzhaiRentData=[];//住宅租金
                let shangpuRentData=[];//商铺租金
                dateAry.forEach(d=>{
                    let obj=res.data.dataList.find(t=>new Date(parseTime(new Date(t.date),'{y}-{m}-{d}')).getTime()==new Date(d).getTime());
                    zhuzhaiPriceData.push(obj?obj['p50000001']:null);
                    shangpuPriceData.push(obj?obj['p50000003']:null);
                    zhuzhaiRentData.push(obj?obj['p50000002']:null);
                    shangpuRentData.push(obj?obj['p50000005']:null)
                })
                nextTick(()=>{
                    var chartDom10 = document.getElementById('chart_'+selIndex.value+'_10');
                    var myChart10 = echarts.getInstanceByDom(chartDom10);
                    if(myChart10){
                        myChart10.clear();
                    }
                    else{
                        myChart10 = echarts.init(chartDom10);
                    }
                    var option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {},
                    grid:{
                        top:40,
                        left:20,
                        right:20,
                        bottom:10,
                        containLabel:true
                    },
                    xAxis: [
                        {
                        type: 'category',
                        data: dateAry,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel:{
                            formatter: function (params) {
                                return new Date(params).getFullYear()
                            }
                        }
                        }
                    ],
                    yAxis: [
                        {
                        type: 'value',
                        alignTicks:true,
                        },
                    ],
                    series: [
                        {
                        name: '住宅房价',
                        type: 'bar',
                        barWidth:'30%',
                        tooltip: {
                            valueFormatter: function (value) {
                            return (value?value:'-') + '元/平方米';
                            }
                        },
                        itemStyle: {
                            color: colors[0]
                        },
                        data: zhuzhaiPriceData
                        },
                        {
                        name: '商铺房价',
                        type: 'bar',
                        barWidth:'30%',
                        tooltip: {
                            valueFormatter: function (value) {
                            return (value?value:'-') + '元/平方米';
                            }
                        },
                        itemStyle: {
                            color: colors[1]
                        },
                        data: shangpuPriceData
                        },
                    ]};
                    option && myChart10.setOption(option);
                    echartsAry.value.push(myChart10);
                    //租金
                    var chartDom11 = document.getElementById('chart_'+selIndex.value+'_11');
                    var myChart11 = echarts.getInstanceByDom(chartDom11);
                    if(myChart11){
                        myChart11.clear();
                    }
                    else{
                        myChart11 = echarts.init(chartDom11);
                    }
                    let option11 = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                            }
                        },
                        legend: {  },
                        grid:{
                            top:40,
                            left:20,
                            right:20,
                            bottom:10,
                            containLabel:true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: dateAry,
                                axisPointer: {
                                    type: 'shadow'
                                }
                            }],
                        yAxis: [
                            {
                                type: 'value',
                                alignTicks:true,
                            },
                            {
                                type: 'value',
                                alignTicks:true,
                            }
                        ],
                        series: [
                        {
                            name: '住宅租金',
                            type: 'line',
                            tooltip: {
                                valueFormatter: function (value) {
                                return (value?value:'-')  + ' 元/月/平方米';
                                }
                            },
                            itemStyle: {
                                color: '#002FA7'
                            },
                            data: zhuzhaiRentData
                        },
                        {
                            name: '商铺租金',
                            type: 'line',
                            yAxisIndex: 1,
                            itemStyle: {
                                color: '#73C0DE'
                            },
                            tooltip: {
                                valueFormatter: function (value) {
                                return (value?value:'-')  + ' 元/日/平方米';
                                }
                            },
                            data: shangpuRentData
                        } 
                        ]
                    };
                    option11 && myChart11.setOption(option11);
                    echartsAry.value.push(myChart11);
                })
            }
        }
        //近5年的在营企业、新增企业
        let curDate=ref((new Date().getFullYear()-1)+'-01-01');
        const getItemDataListFun=async()=>{
            let dateAry=[]
            for(var i=4;i>=0;i--){
                dateAry.push((new Date(curDate.value).getFullYear()-i)+'-01-01');
            }
            let params={
                regionCode:regionCode.value,
                objId:industryList.value[selIndex.value-1].code,
                itemId:'60000503,60000504,60000526,60000527',
                dateType:1,
                date:dateAry[0],
                endDate:dateAry[dateAry.length-1],
                pageSize:1,
            };
            //console.log(params)
            let res=await getRegionItemDataList(params);
            if(res.code==20000 && industryList.value[selIndex.value-1].code==params.objId){
               //console.log(res);
               let runningEpData=[];
               let addEpData=[];
               let punishmentData=[];
               let discreditData=[];
               dateAry.forEach(t=>{
                    let runningFilter=res.data.dataList.find(el=>el.itemId=='60000504' && new Date(el.date).getTime() == new Date(t).getTime());
                    if(runningFilter){
                        runningEpData.push(runningFilter.itemValue);
                    }
                    else{
                        runningEpData.push('-');
                    }
                    let addFilter=res.data.dataList.find(el=>el.itemId=='60000503' && new Date(el.date).getTime() == new Date(t).getTime());
                    if(addFilter){
                        addEpData.push(addFilter.itemValue)
                    }
                    else{
                        addEpData.push('-');
                    }
                    //行政处罚
                    let punishmentFilter=res.data.dataList.find(el=>el.itemId=='60000527' && new Date(el.date).getTime() == new Date(t).getTime());
                    punishmentData.push(punishmentFilter?punishmentFilter.itemValue:0);
                    //失信被执行人
                    let discreditFilter=res.data.dataList.find(el=>el.itemId=='60000526' && new Date(el.date).getTime() == new Date(t).getTime());
                    discreditData.push(discreditFilter?discreditFilter.itemValue:0);
               })
               nextTick(()=>{
                   var chartDom = document.getElementById('chart_'+selIndex.value+'_1');
                    var myChart = echarts.getInstanceByDom(chartDom);
                    if(myChart){
                        myChart.clear();
                    }
                    else{
                        myChart = echarts.init(chartDom);
                    }
                    var option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    grid:{
                        top:40,
                        left:20,
                        right:20,
                        bottom:10,
                        containLabel:true
                    },
                    xAxis: [
                        {
                        type: 'category',
                        data: dateAry,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel:{
                            formatter: function (params) {
                                return new Date(params).getFullYear()
                            }
                        }
                        }
                    ],
                    yAxis: [
                        {
                        type: 'value',
                        alignTicks:true,
                        },
                    ],
                    series: [
                        {
                        name: '在营企业',
                        type: 'bar',
                        barWidth:'30%',
                        tooltip: {
                            valueFormatter: function (value) {
                            return (value!=null?value:'-') + '家';
                            }
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#83bff6' },
                            { offset: 0.5, color: '#002FA7' },
                            { offset: 1, color: '#002FA7' }
                            ])
                        },
                        emphasis: {
                            itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.7, color: '#2378f7' },
                                { offset: 1, color: '#2378f7' },
                            ])
                            }
                        },
                        data: runningEpData
                        },
                    ]};
                    option && myChart.setOption(option);
                    echartsAry.value.push(myChart);
                    //失信被执行人
                    var chartDom7 = document.getElementById('chart_'+selIndex.value+'_7');
                    var myChart7 = echarts.getInstanceByDom(chartDom7);
                    if(myChart7){
                        myChart7.clear();
                    }
                    else{
                        myChart7 = echarts.init(chartDom7);
                    }
                    option.series[0].name='失信被执行人的企业数'
                    option.series[0].data=discreditData;
                    option && myChart7.setOption(option);
                    echartsAry.value.push(myChart7);

                    //行政处罚
                    var chartDom8 = document.getElementById('chart_'+selIndex.value+'_8');
                    var myChart8 = echarts.getInstanceByDom(chartDom8);
                    if(myChart8){
                        myChart8.clear();
                    }
                    else{
                        myChart8 = echarts.init(chartDom8);
                    }
                    option.series[0].name='行政处罚的企业数'
                    option.series[0].data=punishmentData;
                    option && myChart8.setOption(option);
                    echartsAry.value.push(myChart8);

                    //新增企业折线图表
                    var chartDom2 = document.getElementById('chart_'+selIndex.value+'_2');
                    var myChart2 = echarts.getInstanceByDom(chartDom2);
                    if(myChart2){
                        myChart2.clear();
                    }
                    else{
                        myChart2 = echarts.init(chartDom2);
                    }
                    var option2 = {
                        color:[colors[1]],
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },
                        grid:{
                            top:40,
                            left:20,
                            right:20,
                            bottom:10,
                            containLabel:true
                        },
                        xAxis: [
                            {
                            type: 'category',
                            data: dateAry,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                formatter: function (params) {
                                    return new Date(params).getFullYear()
                                }
                            }
                            }
                        ],
                        yAxis: [
                            {
                            type: 'value',
                            alignTicks:true,
                            },
                        ],
                        series: [
                            {
                            name: '新增企业',
                            type: 'line',
                            smooth: true,
                            tooltip: {
                                valueFormatter: function (value) {
                                return (value?value:'-') + '家';
                                }
                            },
                            data: addEpData
                            },
                    ]};
                    option2 && myChart2.setOption(option2);
                    echartsAry.value.push(myChart2);
               })
            }
        }
        //获取下级地区最新一年新增企业数量
        let addEpNumOfRegionList=ref();
        const getNextRegionAddEpNumFun=async()=>{
            let param={regionCode:regionCode.value,
            date:curDate.value,dateType:2,
            objId:industryList.value[selIndex.value-1].code,
            itemId:'60000503'};
            //console.log(param);
            let res=await getNextRegionItemData(param);
            //console.log(res);
            if(res.code==20000 && industryList.value[selIndex.value-1].code==param.objId){
                addEpNumOfRegionList.value=res.data;
            }
        }
        //最新一年同级地区新增企业数量
        // let addEpList=ref();//新增企业的top5
        // let selRegion=ref('');//选中的地区名称
        const getReionAddEpNumFun= async()=>{
            let seriesData=[];
            let param={regionCode:regionCode.value.substr(0,2)+'0000',date:curDate.value,objId:industryList.value[selIndex.value-1].code,itemId:'60000503'};
            //console.log(param);
            let res=await getNextRegionItemData(param);
            //console.log(res);
            if(res.code==20000 && industryList.value[selIndex.value-1].code==param.objId){
                //补全本省的所有城市，有些地区的新增企业数量没有，返回的数据没有此地区，所以要补全地区
                cityList.forEach(t=>{
                    let obj=res.data.find(el=>el.regionCode==t.code);
                   
                    if(t.code==regionCode.value){
                        seriesData.push( {
                            regionCode:t.code,
                            value: obj?obj.itemValue:null,
                            name:t.name,
                            itemStyle: {
                                color: '#e6a23c'
                            }
                        });
                    }
                    else{
                        seriesData.push({regionCode:t.code, name:t.name, value: obj?obj.itemValue:null});
                    }
                })
                seriesData= sortKey(seriesData,'value');
                nextTick(()=>{
                    var chartDom3 = document.getElementById('chart_'+selIndex.value+'_3');
                    var myChart3 = echarts.getInstanceByDom(chartDom3);
                    if(myChart3){
                        myChart3.clear();
                    }
                    else{
                        myChart3 = echarts.init(chartDom3);
                    }
                    let option3 = {
                        color:[colors[0]],
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                            type: 'shadow'
                            }
                        },
                        // legend: {},
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            top:30,
                            containLabel: true
                        },
                        xAxis: {
                            type: 'value',
                            boundaryGap: [0, 0.01]
                        },
                        yAxis: {
                            type: 'category',
                            data: seriesData.map(t=>t.name),
                        },
                        series: [
                            {
                            name: '新增企业',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                return (value?value:'-') + '家';
                                }
                            },
                            data:seriesData
                        }]
                    };
                    option3 && myChart3.setOption(option3);
                    // myChart3.on('click', function(params) {
                    //     let obj= seriesData[params.dataIndex];
                    //     selRegion.value=obj.name;
                    //     getAddEpListFun(obj.regionCode);
                        
                    // });
                    //默认当前城市
                    // if(res.data && res.data.length>0 && industryList.value[selIndex.value-1].code==param.objId){
                    //     let obj=res.data.find(t=>t.regionCode==regionCode.value);
                    //     if(obj){
                    //         selRegion.value=obj.regionName;
                    //     }
                    //     else{
                    //         selRegion.value=res.data[0].regionName;
                    //     }
                    //     getAddEpListFun(regionCode.value);
                    // }
                    echartsAry.value.push(myChart3);
                })
            }
        };
        //获取选中地区的top5的新增企业(注册资本排序)
        // const getAddEpListFun= async(regionCode)=>{
        //     let p={
        //         province:regionCode.substr(0,2)+'0000',
        //         city:regionCode,
        //         createDate:curDate.value+','+(new Date(curDate.value).getFullYear()+1)+'-01-01',
        //         industryCodeA:industryList.value[selIndex.value-1].code,
        //         pageIndex:1,
        //         pageSize:5,
        //         orderFields:'start_date desc'
        //     };
        //     //console.log(p);
        //     let result=await getRegionEpList(p);
        //     //console.log(result);
        //     if(result.code==20000){
        //         addEpList.value=result.data;
        //     }
        // }
        //获取各个城市的专利、作品著作权、软件著作权的数量
        let levelsList=ref([]);
        let epAnalysisList=ref([]);//各城市的在营企业数量、在营企业总注册资本、在营企业总参保人数
        let epNum=ref('-');//企业数量
        let registeredcapital=ref('-');//注册资本
        let insuredNum=ref('-')//参保人数
        const getRegionWorksFun=async()=>{
            levelsList.value=[];
            epAnalysisList.value=[];
            let param={
                regionCode:regionCode.value.substr(0,2)+'0000',
                objId:industryList.value[selIndex.value-1].code,
                itemId:'60000515,60000516,60000525,60000500,60000501,60000502'};
                //console.log(param);
            let res=await getNextRegionItemData(param);
            //console.log(res);
            if(res.code==20000 && industryList.value[selIndex.value-1].code==param.objId){
                epAnalysisList.value=[];
                levelsList.value=[];
                cityList.forEach(t=>{
                    let patentObj=res.data.find(el=>el.itemId=='60000515' && el.regionCode==t.code);
                    let worksrightObj=res.data.find(el=>el.itemId=='60000516' && el.regionCode==t.code);
                    let copyrightObj=res.data.find(el=>el.itemId=='60000525' && el.regionCode==t.code);
                    levelsList.value.push({
                        regionCode:t.code,
                        regionName:t.name,
                        patentNum:patentObj?patentObj.itemValue:null,
                        worksrightNum:worksrightObj?worksrightObj.itemValue:null,
                        copyrightNum:copyrightObj?copyrightObj.itemValue:null,
                    })
                    let epNumObj=res.data.find(el=>el.itemId=='60000500' && el.regionCode==t.code);
                    let registeredcapitalObj=res.data.find(el=>el.itemId=='60000501' && el.regionCode==t.code);
                    let insuredNumObj=res.data.find(el=>el.itemId=='60000502' && el.regionCode==t.code);
                    if(t.code==regionCode.value){
                        epNum.value=epNumObj?epNumObj.itemValue:'-';
                        registeredcapital.value=registeredcapitalObj?registeredcapitalObj.itemValue:'-';
                        insuredNum.value=insuredNumObj?insuredNumObj.itemValue:'-';
                    }
                    epAnalysisList.value.push({
                        regionCode:t.code,
                        regionName:t.name,
                        epNum:epNumObj?epNumObj.itemValue:null,
                        registeredcapital:registeredcapitalObj?registeredcapitalObj.itemValue:null,
                        insuredNum:insuredNumObj?insuredNumObj.itemValue:null,
                    })
                    getPieDataFun('企业数量',epAnalysisList.value.map(t=>{
                        return {value:t.epNum,name:t.regionName}
                    }));
                    getAnnularPieDataFun('专利',levelsList.value.map(t=>{
                        return {value:t.patentNum,name:t.regionName}
                    }));
                })
            }
        }
        const getPieDataFun=(title,data)=>{
            nextTick(()=>{
                var chartDom12 = document.getElementById('chart_'+selIndex.value+'_12');
                var myChart12 = echarts.getInstanceByDom(chartDom12);
                if(myChart12){
                    myChart12.clear();
                }
                else{
                    myChart12 = echarts.init(chartDom12);
                }
                let option = {
                    color:colors,
                    title: {
                        text: title,
                        left: 'left',
                        textStyle:{
                            color : '#002FA7',
                            fontSize:14,
                        } 
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    legend: {
                        //orient: 'vertical',
                        top: 'bottom'
                    },
                    series: [
                        {
                        name: title,
                        type: 'pie',
                        radius: '50%',
                        data: data,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                        }
                    ]
                };
                option && myChart12.setOption(option);
                echartsAry.value.push(myChart12);
            })
        }
        const getAnnularPieDataFun=(title,data)=>{
            nextTick(()=>{
                var chartDom13 = document.getElementById('chart_'+selIndex.value+'_13');
                var myChart13 = echarts.getInstanceByDom(chartDom13);
                if(myChart13){
                    myChart13.clear();
                }
                else{
                    myChart13 = echarts.init(chartDom13);
                }
                let option = {
                    color:colors,
                    title: {
                        text: title,
                        left: 'left',
                        textStyle:{
                            color : '#002FA7',
                            fontSize:14,
                        } 
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    legend: {
                        //orient: 'vertical',
                        top: 'bottom'
                    },
                    series: [
                        {
                        name: title,
                        type: 'pie',
                        radius: ['40%', '65%'],
                        data: data,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                        }
                    ]
                };
                option && myChart13.setOption(option);
                echartsAry.value.push(myChart13);
            })
        }
        //获取各阶段的企业注销情况
        const getRegionEpCancelFun=async(title,cancelNumItemId,epNumItemId)=>{
            let params={
                regionCode:regionCode.value.substr(0,2)+'0000',
                objId:industryList.value[selIndex.value-1].code,
                itemId:epNumItemId+','+cancelNumItemId,//,60000519,60000520,60000521,60000522,60000523,60000524
                pageSize:1,
            };
            //console.log(params);
            let res=await getNextRegionItemData(params);
            //console.log(res);
            if(res.code==20000){
                let yAxisData=res.data.filter(t=>t.itemId==epNumItemId);
                yAxisData.forEach(el=>{
                    let obj=res.data.find(t=>t.itemId==cancelNumItemId && t.regionCode==el.regionCode);
                    el.cancelNum=obj?obj.itemValue:null;
                    el.cancelRate=obj?obj.itemValue/el.itemValue:null
                })
                yAxisData= sortKey(yAxisData,'cancelRate');
                nextTick(()=>{
                    var chartDom6 = document.getElementById('chart_'+selIndex.value+'_6');
                    var myChart6 = echarts.getInstanceByDom(chartDom6);
                    if(myChart6){
                        myChart6.clear();
                    }
                    else{
                        myChart6 = echarts.init(chartDom6);
                    }
                    let option = {
                        title:{
                            text:title+'的注销企业',
                            left: 'left',
                            textStyle:{
                                color : '#002FA7',
                                fontSize:14,
                            } 
                        },
                        tooltip: {
                            trigger: 'axis',
                            formatter: '{a} <br/>{b} : {c}家'
                        },
                        
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            top:40,
                            containLabel: true
                        },
                        xAxis: {
                            type: 'value',
                            splitLine: { show: false },
                        },
                        yAxis: {
                            type: 'category',
                            data: yAxisData.map(t=>t.regionName)
                        },
                        series: [
                            {
                                name:'注销企业',
                                data: yAxisData.map(t=>{if(t.cancelNum){return t.cancelNum } else{return '-'}  }),
                                type: 'bar',
                                barWidth:'50%',
                                itemStyle: {
                                    color: '#002FA7'
                                },
                                label: {
                                    show: true,
                                    // formatter: function (params) {
                                    // return ((params.value / maxData) * 100).toFixed(1) + ' %';
                                    // },
                                    position: 'right',
                                    offset: [3, 0],
                                    color: '#002FA7',
                                    fontSize: 16
                                }
                            },
                            {
                                data:  yAxisData.map(t=>t.itemValue),
                                type: 'bar',
                                barGap:'-100%',//移动第二个柱子的位置实现重叠
                                z:'-1',//改变这根柱子的层级使这根柱子在下面
                                barWidth:'50%',
                                itemStyle: {
                                    color: 'rgba(0,0,0,0.05)'
                                },
                                tooltip:{show:false},
                                label: {
                                    show: true,
                                    // formatter: function (params) {
                                    // return ((params.value / maxData) * 100).toFixed(1) + ' %';
                                    // },
                                    position: 'right',
                                    offset: [-30, 0],
                                    color: 'rgba(0,0,0,0.2)',
                                    fontSize: 16
                                },
                            }
                        ]
                    };
                    option && myChart6.setOption(option);
                    echartsAry.value.push(myChart6);
                })
            }
        };
        const sortKey=(array, key)=> {
            return array.sort((a, b) => {
                let x = a[key];
                let y = b[key];
                return x > y ? 1 : x < y ? -1 : 0;
            });
        }
        //table的行点击事件，成立日期各个阶段的注销情况
        const rowClickFun=(row)=>{
            //console.log(row);
            getRegionEpCancelFun(row.name,row.cancelNumItemId,row.epNumItemId);
        }
        
        const handleCurrentChange = (val) => {
            //console.log(val);
            currentRow.value = val
        }
        //table的排序事件
        const headerChangeFun=(column)=>{
            //console.log(column);
            if(',epNum,registeredcapital,insuredNum,'.indexOf(','+ column.property+',')>=0){//企业的行业概况
                getPieDataFun(column.label,epAnalysisList.value.map(t=>{
                    return {value:t[column.property],name:t.regionName}
                }));
            }
            else if(',patentNum,worksrightNum,copyrightNum,'.indexOf(','+ column.property+',')>=0){//专利技术
                getAnnularPieDataFun(column.label,levelsList.value.map(t=>{
                    return {value:t[column.property],name:t.regionName}
                }));
            }
        }
        //表格设置高亮
        const tableRowClassName = (obj) => {
            //console.log(obj.row);
            if (obj.row.regionCode === regionCode.value) {
                return 'highlight'
            } 
            return ''
        }
        //单击菜单锚点到相应的位置
        const goAnchor= (selector) => {
            //selLabel.value=selector;
            const obj=document.querySelector('#'+selector);
                
            let to=obj.offsetTop-60;
            //console.log(to);
            //document.querySelector('.current').classList.remove('current');
            //obj.classList.add("current");
            scrollTo('.affix-container .el-scrollbar__wrap',to,800);
        }
        let isShowHeader=ref(false);//是否显示

        // 滚动加载
        const lazyLoading= ()=> {
            const scrollTop = document.querySelector('.affix-container .el-scrollbar__wrap').scrollTop;// 滚动条滚动时，距离顶部的距离
            //const windowHeight = document.documentElement.clientHeight;// 可视区的高度
            //const scrollHeight = document.documentElement.scrollHeight;// 滚动条的总高度
            //console.log(scrollTop);
            if(scrollTop>200){
                isShowHeader.value=true;
                
            }
            else{
                isShowHeader.value=false;
            }
        }
        //监听地区的改变
        watch(
        ()=>regionCode.value,
        (newVal,oldVal)=>{
            //console.log('监听全局变量regionCode',newVal);
            if(!oldVal && newVal){
                //执行
                getIndustryListFun()
            }
        },
        {
            immediate:true,// 这个属性是重点啦
            //deep:true // 深度监听的参数
        }
    )

        //自适应
        let listener=()=> {
        echartsAry.value.forEach(element => {
            element.resize();
        });
        }
        window.addEventListener('resize', listener)//监听resize事件
       
        onBeforeUnmount(()=>{//销毁之前
            window.removeEventListener('scroll',lazyLoading)
            window.removeEventListener('resize',listener);
            echartsAry.value.forEach(element => {
            if (element) {
                element.clear(); //清空图表
                element.dispose(); //释放图表组件
                element = null;
            }
            }); 
        })
        onMounted(()=>{
            //initAMap();
            document.querySelector('.affix-container .el-scrollbar__wrap').addEventListener('scroll',lazyLoading);//监听
        })
        return{
            windowHeight,
            selIndustryFun,
            selEpType,
            selEpTypeFun,
            industryList,
            selIndex,
            mapMarker,
            epNum,
            registeredcapital,
            insuredNum,
            epAnalysisList,
            headerChangeFun,
            epTotal,
            pageIndex,
            pageSize,
            loadingShowOfEpList,
            epList,
            pageChangeFun,
            sortChangeFun,
            sortField,
            ratioOfBigEp,
            ratioOfOversizeEp,
            ratioOfExpandEp,
            ratioOfMatureEp,
            // numOfProvincePatent,
            // numOfProviceWorkright,
            // numOfProviceCopyright,
            levelsList,
            productList,
            curDate,
            addEpNumOfRegionList,
            //addEpList,
            tableRowClassName,
            //selRegion,
            cancelEpList,
            cancelEpListRef,
            rowClickFun,
            currentRow,
            handleCurrentChange,
            isShowHeader,
            goAnchor,
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';

.span-txt{
    line-height: 30px;
    font-weight: bold;
    color: $specialtext-color;
}
.tipMarker.top {
    transform: translate(-50%,-110%);
}
.tipMarker {
    background-color: rgba($themeColor, 0.8);
    border: 1px solid rgba($themeColor, 0.3);
    border-radius: 5px;
    padding: 6px 10px;
    font-size: 14px;
    color:#fff;
    white-space: nowrap;
    display: inline-block;
}
.col-d {
    background: #fff;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-right: -10px;
    padding: 6px 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .name{
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $specialtext-color;
    }
    .num{
        margin: 0;
        font-size: 26px;
        font-weight: 100;
        text-align: center;
    }
    .unit {
        font-size: 12px;
        color: #aaa;
    }
}
.col-d.selcol {
    background: $specialtext-color;
    color: #fff;
    .name{
        color: #fff;
    }
}
.bar{
    width:100%;
    height:8px;
    background:rgba(0,0,0,0.05);
    border-radius:6px;
    .bar_inner{
        height:8px;
        background:$specialtext-color;
        border-radius:6px;
    }
}
.cd_page_nav {
    height: 45px;
    padding: 0 20px;
    background-color: $themeColor;
    width:100%;
    transition: all .5s;
    margin-bottom: 20px;
    span{
        float: left;
        font-size: 18px;
        border-bottom: 2px solid transparent;
        line-height: 42px;
        margin-right: 60px;
        cursor: pointer;
        color: #fff;
    }
}
</style>